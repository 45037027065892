import React from "react";
import isEmpty from "lodash.isempty";
import { REACT_APP_MAP_KEY, MALAGA_CENTER } from "../../constants";

import SearchBox from "./../../components/Map/SearchBox";
import GoogleMap from "./../../components/Map/GoogleMap";
import Marker from "./../../components/Map/Marker";
import Geolocate from './../../components/Geolocate';

export class LocationGoogle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // OTHER MAP
      mapApiLoaded: false,
      mapInstance: null,
      mapApi: null,
      geocoder: null,
      places: [],
      posLat:  null,
      posLng:  null,
    };
    this.child = React.createRef();
    
    this.handleGeolocation = this.handleGeolocation.bind(this)
  }

  // OTHER MAP
  apiHasLoaded = (map, maps) => {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
      geocoder: new maps.Geocoder()      
    });
  };  


  addPlace = place => {
    const places = [place];
    this.setState({ places });
  };

  createMapOptions = maps => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      gestureHandling:
        "greedy" /*,
      styles: [
        {
          stylers: [
            { saturation: -100 },
            { gamma: 0.8 },
            { lightness: 4 },
            { visibility: "on" }
          ]
        }
      ]*/
    };
  };

  handleGeolocation(data) {
    const { coords } = data;
    const { latitude, longitude } = coords;
    this.setState(
      {
        posLat: latitude,
        posLng: longitude,
      },
      () => {
        // Acceder al estado actualizado en el callback
        const latlng =  new this.state.mapApi.LatLng({ lat: this.state.posLat, lng:  this.state.posLng });
        const { mapInstance } = this.state;
        if (mapInstance) {
          mapInstance.setCenter(latlng); 
          mapInstance.setZoom(15); 
 
          const geocoder = new this.state.mapApi.Geocoder();
         geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === this.state.mapApi.GeocoderStatus.OK) {
        const { 0: place } = results;
        if (!place.geometry) return;
        // editar las coordenadas para mantener el click
        place.geometry.location = latlng;
        mapInstance.setCenter(place.geometry.location);
        mapInstance.setZoom(mapInstance.getZoom());
        const places = [];
        places.push(place);
        this.setState({ places });
        this.child.current.handleSelectSuggest(place, true);

        // this.searchInput.value = place.formatted_address;
        // this.searchInput.blur();
      } else {
        console.error("Geocode error: " + status);
      }
    });
        } else {
          console.error("mapInstance no está inicializado");
        }
      }
    );
    
}

  _onClick = ({ x, y, lat, lng, event }) => {
    const latlng = new this.state.mapApi.LatLng({ lat, lng }); // `${lat}, ${lng}`;
    const geocoder = new this.state.mapApi.Geocoder();

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === this.state.mapApi.GeocoderStatus.OK) {
        const { 0: place } = results;

        const { mapInstance } = this.state;
        if (!place.geometry) return;

        // editar las coordenadas para mantener el click
        place.geometry.location = latlng;
        mapInstance.setCenter(place.geometry.location);
        mapInstance.setZoom(mapInstance.getZoom());

        const places = [];
        places.push(place);

        this.setState({ places });

        this.child.current.handleSelectSuggest(place, true);

        // this.searchInput.value = place.formatted_address;
        // this.searchInput.blur();
      } else {
        console.error("Geocode error: " + status);
      }
    });
  };

  render() {
    // OTHER MAP
    const { places, mapApiLoaded, mapInstance, mapApi } = this.state;
    const { entity,t } = this.props;

    return (
      <>
        {mapApiLoaded && (
          <SearchBox
            map={mapInstance}
            mapApi={mapApi}
            addplace={this.addPlace}
            ref={this.child}
            {...this.props}
          />
        )}
          {mapApiLoaded && ( <div style={{ display: "none" }}>
        <Geolocate onSuccess={this.handleGeolocation} t={t} /></div>)}
        <GoogleMap
          tabIndex={-1}
          defaultZoom={13}
          defaultCenter={ entity 
              ? [parseFloat(entity.Latitud), parseFloat(entity.Longitud)]
              : MALAGA_CENTER
          }
          bootstrapURLKeys={{
            key: REACT_APP_MAP_KEY,
            libraries: ["places", "geometry"]
          }}
          yesIWantToUseGoogleMapApiInternals
          options={this.createMapOptions}
          onClick={this._onClick}
          onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
        >          
          {!isEmpty(places) &&
            places.map(
              place =>
                place && (
                  <Marker
                    key={place.id}
                    text={place.name || ""}
                    lat={place.geometry.location.lat()}
                    lng={place.geometry.location.lng()}
                  />
                )
            )}
        </GoogleMap>
      </>
    );
  }
}

export default LocationGoogle;
