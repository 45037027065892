import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withFormik } from "formik";
import * as Yup from "yup";
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import IntlTelTextInput from "react-intl-tel-input";
import {
  InputGroup,
  Card,
  Button,
  FormControl,
  Row,
  Col,
  Form,
  ProgressBar,
  Alert,
  Tab
} from "react-bootstrap";
import { DynamicBackground, EntitySearch } from "../../components";
import "./RegisterPage.css";
import "react-intl-tel-input/dist/main.css";
import { registerRequest, requestError, entityActions } from "../../actions";
import { defaultAyuntamientoID } from '../../constants';
import { forwardTo } from "./../../helpers/forward";
import { isWebpSupported } from "react-image-webp/dist/utils";
import { LegalAdviceModal } from "../../components/LegalAdvice/LegalAdviceModal";

import { authApi } from "../../helpers";

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: true,
      showOk: true,
      ao: queryString.parse(this.props.location.search),
      hideChangeEntity: defaultAyuntamientoID == 1 || props.entity && props.entity.AyuntamientoID == 429 ? true : false,
    };

    // INPUT PHONE NUMBER
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
    this.onCheckBoxChangeNewsletter = this.onCheckBoxChangeNewsletter.bind(this);

    this.handleChangeEntity = this.handleChangeEntity.bind(this);
    this.setShowError = this.setShowError.bind(this);
    this.setShowOk = this.setShowOk.bind(this);
  }

  onChange(status, value, country, number, id, event) {
    const { setFieldValue, setFieldTouched } = this.props;
    setFieldValue("mobile", number);
    setFieldTouched("mobile", true);
  }

  onBlur(status, value, country, number, id, event) {
    const { setFieldTouched, setFieldValue } = this.props;
    setFieldTouched("mobile", true);
  }
  
  onCheckBoxChange() {
    const { setFieldValue, values } = this.props;
    const { consent } = values;
    setFieldValue("consent", !consent);
  }

  onCheckBoxChangeNewsletter() {
    const { setFieldValue, values } = this.props;
    const { newsletter } = values;
    setFieldValue("newsletter", !newsletter);
  }

  setShowError(showError) {
    this.setState({ showError });
    if (!showError) this.props.dispatch(requestError(undefined));
  }

  setShowOk(showOk) {
    this.setState({ showOk });
    forwardTo("/login");
  }

  handleChangeEntity(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(entityActions.changeEntity());
  }

  handleSelectedEntity(entity) {
    //this.setState({ aytoName: entity.Nombre });
  }

  handleChangeBackground(entity) {
    switch (entity && entity.AyuntamientoID) {
      case 206:
        return "";
      case 180:
        return entity.CabeceraApp;
      case 429:
        return isWebpSupported()
          ? "https://gecorcloud.com/images/turisme1.webp"
          : "https://gecorcloud.com/images/turisme1.jpg";
      default:
        return isWebpSupported()
          ? "https://gecorcloud.com/images/login.webp"
          : "https://gecorcloud.com/images/login.png";
    }
  }

  handleChangeCardColor(entity) {
    switch (entity && entity.AyuntamientoID) {
      case 429:
        return "containerPerfilViladecans register-page";
      default:
        return "containerPerfil register-page";
    }
  }

  render() {
    const { ao, showError, showOk, hideChangeEntity } = this.state;

    const {
      values,
      touched,
      errors,
      // dirty,
      handleChange,
      // setFieldValue,
      setSubmitting,
      isSubmitting,
      resetForm,
      handleSubmit,
      isValid,
      error,
      t,
      registered,
      entity
    } = this.props;

    return (
      <DynamicBackground
        customOverlay="rgba(2, 85, 85, 0.5)"
        background={this.handleChangeBackground(entity)}
        >
        <div className="containerUser">
          <Row className="user-details">
            <Form onSubmit={handleSubmit}>
              {error && (
                /*<span className="red-text helper-text"> {error} </span>*/
               <Alert
                  show={showError}
                  variant="danger"
                  onClose={() => {
                    this.setShowError(false);
                    setSubmitting(false);
                    this.setShowError(true);
                  }}
                  dismissible
                >
                  <Alert.Heading>{t("register.errorTitle")}</Alert.Heading>
                  {error && error == "User already exists" && <p>{t("register.exist")}</p>}
                  {error && error != "User already exists" && <p>{error}</p>}
                  <div className="d-flex justify-content-end">
                    <Button
                      onClick={() => {
                        this.setShowError(false);
                        setSubmitting(false);
                        this.setShowError(true);
                      }}
                      variant="outline-danger"
                    >
                      {t("register.tryAgainButton")}
                    </Button>
                  </div>
                </Alert>
              )}
              {registered && (
                /*<span className="red-text helper-text"> {error} </span>*/
                <Alert show={showOk} variant="success">
                  <Alert.Heading>
                    {t("register.signinSuccessTitle")}
                  </Alert.Heading>
                  <p>{t("register.signinSuccessMessage")}</p>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <Button
                      onClick={() => {
                        this.setShowOk(false);
                        setSubmitting(false);
                        resetForm(registerValuesFormik);
                        this.setShowOk(false);
                      }}
                      variant="outline-success"
                    >
                      {t("register.signinSuccessButton")}
                    </Button>
                  </div>
                </Alert>
              )}
              {((ao && !ao.ao) || !ao) && entity ? (
                <Row className="center">
                  <Col sm={12}>
                    <span className="register-title">
                    {t("login.btn-register", {entityName: entity.Nombre ? entity.Nombre:"Gecor"})}
                      {!hideChangeEntity && (
                        <span>
                         {" "} - {" "}
                          <a href="#" onClick={this.handleChangeEntity}>
                            {t("login.ch-ayto")}
                          </a>
                        </span>
                      )}
                    </span>
                  </Col>
                </Row>
              ) : (
                  <EntitySearch
                    redirect={false}
                    onSelected={this.handleSelectedEntity}
                    ao={ao}
                  />
                )}
              {entity && (
                <div className={this.handleChangeCardColor(entity)}>
                  <Card>
                    <Card.Body>
                      <Card.Title>
                        {" "}
                        <h4 className="omb_authTitle inicio-sesion">
                          {t("register.title")}
                          <Link className="" to="/login">
                            {t("register.title2")}
                          </Link>
                        </h4>
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        <hr className="line2"></hr>
                      </Card.Subtitle>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            {t("register.p1")}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                          isValid={touched.name && !errors.name}
                          disabled={isSubmitting}                          
                          autoComplete="given-name"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </InputGroup>

                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            {t("register.p2")}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          isValid={touched.email && !errors.email}
                          disabled={isSubmitting}                          
                          autoComplete="email"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </InputGroup>

                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            {t("register.p2ir")}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          type="email"
                          name="emailConfirmation"
                          value={values.emailConfirmation}
                          onChange={handleChange}
                          isInvalid={!!errors.emailConfirmation}
                          isValid={
                            touched.emailConfirmation &&
                            !errors.emailConfirmation
                          }
                          disabled={isSubmitting}                         
                          autoComplete="email"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.emailConfirmation}
                        </Form.Control.Feedback>
                      </InputGroup>

                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            {t("register.p3")}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                          isValid={touched.password && !errors.password}
                          disabled={isSubmitting}                    
                          autoComplete="new-password"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </InputGroup>

                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            {t("register.p3ir")}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          type="password"
                          name="passwordConfirmation"
                          value={values.passwordConfirmation}
                          onChange={handleChange}
                          isInvalid={!!errors.passwordConfirmation}
                          isValid={
                            touched.passwordConfirmation &&
                            !errors.passwordConfirmation
                          }
                          disabled={isSubmitting}              
                          autoComplete="new-password"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.passwordConfirmation}
                        </Form.Control.Feedback>
                      </InputGroup>
                      
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            {t("register.p6")}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          type="text"
                          name="nie"
                          value={values.nie}
                          onChange={handleChange}
                          isInvalid={!!errors.nie}
                          isValid={touched.nie && !errors.nie}
                          disabled={isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.nie}
                        </Form.Control.Feedback>
                      </InputGroup>

                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            {t("register.p4")}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <IntlTelTextInput
                          input
                          fieldName="mobile"
                          containerClassName="intl-tel-input"
                          placeholder=" "
                          inputClassName="form-control"
                          preferredCountries={["es"]}
                          onPhoneNumberChange={this.onChange}
                          onPhoneNumberBlur={this.onBlur}
                          isInvalid={!!errors.mobile}
                          isValid={touched.mobile && !errors.mobile}
                          disabled={isSubmitting}         
                          autoComplete="tel"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.mobile}
                        </Form.Control.Feedback>
                      </InputGroup>

                      <Form.Group controlId="formBasicChecbox">
                        <div className="legal-advice-container">
                          <Form.Check
                            type="checkbox"
                            name="consent"
                            label={t("register.p5")}
                            onChange={this.onCheckBoxChange}
                            isInvalid={!!errors.consent}
                            isValid={touched.consent && !errors.consent}
                            disabled={isSubmitting}
                          />
                          &nbsp;
                          <LegalAdviceModal />                         
                          {/* <a target="_blank" rel="noopener noreferrer" href="/legal-advice">
                            {t("register.p5i")}
                          </a> */}
                         
                          &nbsp;
                          <a target="_blank" rel="noopener noreferrer" href="/security-policy">
                           <b> {t("register.p5s")}</b>
                          </a>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.consent}
                        </Form.Control.Feedback>
                      </Form.Group>

                     {entity.EnviarCorreoMensualCiudadano &&  <Form.Group controlId="formBasicChecboxNewsletter">
                        <div className="newsletter">
                          <Form.Check
                            type="checkbox"
                            name="newsletter"
                            label={t("register.p7")}
                            onChange={this.onCheckBoxChangeNewsletter}
                            disabled={isSubmitting}
                            value={values.newsletter}
                          />        
                        </div>
                      </Form.Group>}

                      {!isSubmitting && (<div>
                        <Button variant="secondary" onClick={() => forwardTo('/login')} className="float-start" to="/login">
                          {t("register.cancel")}
                        </Button>
                        <Button
                          waves="light"
                          type="submit"
                          disabled={!isValid}
                          s={12}
                          m={12}
                        >
                          {t("register.btn")}
                        </Button></div>
                      )}

                      {isSubmitting && <ProgressBar animated now={100} />}
                    </Card.Body>
                  </Card>
                </div>
              )}
            </Form>
          </Row>
        </div>
      </DynamicBackground>
    );
  }
}

const registerValuesFormik = {
  name: "",
  email: "",
  emailConfirmation: "",
  password: "",
  passwordConfirmation: "",
  nie: "",
  mobile: "",
  newsletter: false,
  consent: false,
};

const tRegisterForm = withTranslation()(RegisterForm);
const formikEnhancer = withFormik({
  enableReinitialize: true,
  validationSchema: ({ t }) => {
    return Yup.lazy(values => {
      return Yup.object().shape({
        name: Yup.string()
          .required(t("register.required_name"))
          .min(1),
        email: Yup.string()
          .required(t("register.required_email"))
          .email(t("register.p2t")),
        emailConfirmation: Yup.string()
          .required(t("register.required_email2"))
          .email(t("register.p2t"))
          .oneOf([values.email], t("register.p2ns")),
        password: Yup.string()
          .min(6,t("register.min_6"))
          .required(t("register.required_password")),
        passwordConfirmation: Yup.string()
          .required(t("register.required_password2"))
          .oneOf([values.password], t("register.p3ns")),
        nie: Yup.string().required(t("register.required_nif")).test({
          name: 'nie',
          message: t("register.p6t"),
          test(value, ctx) {
            return authApi.validateSpanishId(value);
          }
        }),
        mobile: Yup.string().required(t("register.required_mobile")).test({
          name: 'mobile',
          message: t("register.p4t"),
          test(value, ctx) {
            return authApi.validateMobile(values.mobile);
          }
        }),
        newsletter: Yup.bool(),
        consent: Yup.bool()
          .test("consent", t("register.p5t"), value => value === true)
          .required(),
      });
    });
  },
  mapPropsToValues: () => registerValuesFormik,
  handleSubmit: (payload, { props, f, resetForm }) => {    
    props.dispatch(registerRequest(payload));
  },
  displayName: "RegisterForm"
})(tRegisterForm);

const mapStateToProps = state => {
  const { registered, error } = state.users;
  const { entity } = state.entities;
  const { entityConfig } = state.entities;

  return {
    entity,
    entityConfig,
    registered,
    error
  };
}

const tFormikEnhancer = withTranslation()(formikEnhancer);
const connectedRegisterPage = connect(mapStateToProps)(tFormikEnhancer);
export { connectedRegisterPage as RegisterPage };
